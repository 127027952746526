/* src/index.css */
html, body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background-image: url('./background.svg'); /* путь к вашему SVG файлу */
  background-size: cover; /* адаптирует размер SVG к размеру элемента */
  background-position: center; /* центрирует изображение */
  background-repeat: no-repeat; /* не повторяет изображение */
  font-family: "Poppins", sans-serif;
  color: white; /* устанавливаем цвет текста белым */
}

.poppins-medium {
  font-weight: 500;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
